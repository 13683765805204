<template>
  <div v-if="logs">
    <v-timeline
      dense
      reverse
    >
      <deliveryRequestLogItem
        v-for="log in logs"
        :key="log.id"
        :log="log"
      ></deliveryRequestLogItem>
    </v-timeline>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  computed: {
    logs() {
      if(!Array.isArray(this.storeData)) return null
      return this.storeData
    },
  },
  components: {
    deliveryRequestLogItem: () => import('./deliveryRequestLogItem.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
